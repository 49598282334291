import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BreakpointTypeEnum } from '@shared/types/screen-breakpoints';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private _marketApi: string;
  private _livingApi: string;
  private _apiVersion: {
    v0: string;
    v1: string;
    v2: string;
  };
  private _frontPath: string;
  public v0: string;
  public v1: string;
  public v2: string;
  public storage: string;
  public savedParentUrl: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public screenBreakpoint$ = new BehaviorSubject<BreakpointTypeEnum>(null);

  constructor(private readonly http: HttpClient) {
    this._marketApi = `${location.origin}${
      environment.production ? '' : environment.apiMarketPath + '/'
    }`;
    this._livingApi = environment.production
      ? 'https://api.living.ru/'
      : `${location.origin}${environment.apiLivingPath}/`;
    this._apiVersion = environment.apiVersion;
    this.v0 = `${location.origin}${environment.production ? '' : environment.apiMarketPath}${
      environment.apiVersion.v0
    }/`;
    this.v1 = `${location.origin}${environment.production ? '' : environment.apiMarketPath}${
      environment.apiVersion.v1
    }/`;
    this.v2 = `${location.origin}${environment.production ? '' : environment.apiMarketPath}${
      environment.apiVersion.v2
    }/`;
    this._frontPath = location.origin;
    this.storage = `${location.origin}${environment.production ? '' : environment.apiMarketPath}`;
  }

  public get marketApi(): string {
    return this._marketApi;
  }

  public get livingApi(): string {
    return this._livingApi;
  }

  public get apiVersion(): {
    v0: string;
    v1: string;
    v2: string;
  } {
    return this._apiVersion;
  }

  public get frontPath(): string {
    return this._frontPath;
  }

  public get version(): string {
    return localStorage.getItem('version');
  }

  public set version(value: string) {
    localStorage.setItem('version', value);
  }

  public getConfiguratorVersion(): Observable<{ match: boolean; anonymous_user: boolean }> {
    return this.http.get<{ match: boolean; anonymous_user: boolean }>(
      `${this.v2}client/configurator/match-version`,
    );
  }
}
